import styled from "styled-components";
import { ReactComponent as RivusLogo } from "../assets/core/logo.svg";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  margin-left: 12px;
`;

export default function Logo({ onClick }: any) {
  return (
    <LogoContainer onClick={onClick}>
      <RivusLogo />
      <Title>Rivus</Title>
    </LogoContainer>
  );
}
