import { ConnectButton as ConnectSuiButton } from "@mysten/dapp-kit";

const buttonStyle = {
  backgroundColor: "var(--fill-primary)",
  color: "var(--text-primary)",
  borderRadius: 36,
  padding: "12px 24px",
  cursor: "pointer",
};

export default function ConnectButton() {
  return (
    <div>
      <nav>
        <ConnectSuiButton style={buttonStyle} />
      </nav>
    </div>
  );
}
