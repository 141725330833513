import React, { useCallback } from "react";
import ConnectButton from "../ConnectButton";
import Logo from "../Logo";
import {
  BtnContainer,
  HeaderContainer,
  NavContainer,
  NavItem,
  SecondaryButton,
} from "./Header.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { HEADER_ROUTES, ROUTES } from "../../constants/routes";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickPath = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <nav>
      <HeaderContainer>
        <Logo onClick={() => onClickPath(ROUTES.HOME.path)} />

        <NavContainer>
          {HEADER_ROUTES.map(({ path, label }) => (
            <NavItem
              onClick={() => onClickPath(path)}
              active={path === location.pathname}
            >
              {label}
            </NavItem>
          ))}
        </NavContainer>

        <BtnContainer>
          <SecondaryButton>Mainnet RPC</SecondaryButton>
          <ConnectButton />
        </BtnContainer>
      </HeaderContainer>
    </nav>
  );
}
