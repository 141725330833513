import styled from "@emotion/styled";

export const BaseTabs = styled.div`
  /* height: 100%; */
  /* width: 100%; */
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */
  box-sizing: border-box;
`;

export const BaseTab = styled.div`
  /* height: 100%; */
  /* width: 100%; */
  overflow: auto;
`;

export const BaseTabsSwitcher = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--background-primary);
  border-radius: 20rem;
  position: relative;

  padding: 12px 16px;
  gap: 8px;
  margin-bottom: 16px;

  box-sizing: border-box;
`;

export const BaseTabsSwitcherItem = styled.div<{ active?: boolean }>`
  padding: 12px 16px;
  font-size: 16px;
  line-height: 130%;
  font-weight: 500;
  flex: 1;
  cursor: pointer;
  border-radius: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: var(--text-primary);
  min-width: 120px;
  transition: background-color 0.3s ease-in-out;

  ${({ active }) =>
    active
      ? `
  
      background-color: var(--fill-primary);
  `
      : ""}
`;
