import React, { useMemo, useState } from "react";

import {
  BaseTabs,
  BaseTabsSwitcher,
  BaseTabsSwitcherItem,
} from "./Tabs.styles";

function Tab(props: any) {
  return <div className="base-tab">{props.children}</div>;
}

function Tabs(props: any) {
  const [activeTab, setActiveTab] = useState(0);
  const { children } = props;

  const tabItems = useMemo(
    () =>
      React.Children.map(children, (element, index) => {
        if (!React.isValidElement(element)) {
          return;
        }

        // @ts-ignore
        const { label } = element.props;

        return (
          <BaseTabsSwitcherItem
            onClick={() => setActiveTab(index)}
            active={index === activeTab}
          >
            {label}
          </BaseTabsSwitcherItem>
        );
      }),
    [children, activeTab]
  );

  return (
    <BaseTabs>
      <BaseTabsSwitcher>{tabItems}</BaseTabsSwitcher>
      {React.Children.map(children, (element, index) => {
        if (index === activeTab) {
          return element;
        }
      })}
    </BaseTabs>
  );
}

Tabs.Tab = Tab;

export default Tabs;
