export const ROUTES = {
  HOME: {
    key: "home",
    label: "A.R.T",
    path: "/",
  },
  STAKE: {
    key: "stake",
    label: "Stake",
    path: "/stake",
  },
  VALIDATORS: {
    key: "validators",
    label: "Validators",
    path: "/validators",
  },
  NOT_FOUND: {
    key: "not-found",
    label: "Not Found",
    path: "/404", // also set up for '*'
  },
};

export const HEADER_ROUTES = [ROUTES.STAKE, ROUTES.VALIDATORS];
