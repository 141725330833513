import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 64px;
  /* border-bottom: thin solid var(--fill-secondary); */
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SecondaryButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--fill-primary);
  color: var(--text-secondary);
  border-radius: 36px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const NavItem = styled.div<{ active?: boolean }>`
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  cursor: pointer;
  color: var(--text-secondary);
  padding: 8px;
  border-bottom: 4px solid transparent;
  transition: 0.3s;

  :hover {
    border-bottom: 4px solid var(--fill-secondary);
  }

  ${({ active }) =>
    active
      ? `
    color: var(--text-primary);
    border-bottom: 4px solid var(--fill-primary) !important;
  `
      : ""}
`;
