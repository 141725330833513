import React from "react";
import { Container, Heading } from "./NotFound.styles";

export default function NotFound() {
  return (
    <Container>
      <Heading>404</Heading>
      This page could not be found.
    </Container>
  );
}
