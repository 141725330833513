import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  gap: 32px;
  padding: 16px 64px;
  align-items: center;
  justify-content: center;
`;

export const SocialItem = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const SocialLogo = styled.img`
  height: 12px;
  width: 12px;
`;

export const DiscordLogo = styled.img`
  height: 16px;
  width: 16px;
`;

export const SocialLabel = styled.div`
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
`;