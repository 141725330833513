// Libraries
import { SuiClientProvider, WalletProvider, ThemeVars } from "@mysten/dapp-kit";
import { getFullnodeUrl } from "@mysten/sui.js/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Styling
import "./App.css";
import "@mysten/dapp-kit/dist/index.css";
import "./styles/_colors.css";
import backdropImage from "./assets/core/backdrop.webp";

// Custom Components
import Header from "./components/Header";
import styled from "styled-components";
import Staking from "./pages/Staking";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import NotFound from "./pages/NotFound";

const Backdrop = styled.img`
  position: absolute;
  width: 100vw;
  max-width: 1200px;
  bottom: -40%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* opacity: 0.3; */
  animation: glow 3s ease-in-out infinite;

  @keyframes glow {
    0%,
    100% {
      opacity: 0.3;
      transform: scale(1);
    }

    50% {
      opacity: 0.2;
      transform: scale(0.95);
    }
  }
`;

// Light theme copied from dapp-kit
export const darkTheme: ThemeVars = {
  blurs: {
    modalOverlay: "blur(0)",
  },
  backgroundColors: {
    primaryButton: "#5A4CCB",
    primaryButtonHover: "#252b49",
    outlineButtonHover: "#252b49",
    modalOverlay: "rgba(24 36 53 / 20%)",
    modalPrimary: "#121318",
    modalSecondary: "#0D0F12",
    iconButton: "transparent",
    iconButtonHover: "#F0F1F2",
    dropdownMenu: "#121318",
    dropdownMenuSeparator: "#0D0F12",
    walletItemSelected: "#000",
    walletItemHover: "#3C424226",
  },
  borderColors: {
    outlineButton: "#5A4CCB",
  },
  colors: {
    primaryButton: "#ffffff",
    outlineButton: "#ffffff",
    iconButton: "#000000",
    body: "#ffffff",
    bodyMuted: "#cad1de",
    bodyDanger: "#FF794B",
  },
  radii: {
    small: "12px",
    medium: "16px",
    large: "24px",
    xlarge: "32px",
  },
  shadows: {
    primaryButton: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    walletItemSelected: "0px 2px 6px rgba(0, 0, 0, 0.05)",
  },
  fontWeights: {
    normal: "400",
    medium: "500",
    bold: "600",
  },
  fontSizes: {
    small: "14px",
    medium: "16px",
    large: "18px",
    xlarge: "20px",
  },
  typography: {
    fontFamily: "IBM Plex Sans, Calibri, sans-serif",
    fontStyle: "normal",
    lineHeight: "1.3",
    letterSpacing: "1",
  },
};

const queryClient = new QueryClient();

// Config options for the networks you want to connect to
const networks = {
  devnet: { url: getFullnodeUrl("devnet") },
  mainnet: { url: getFullnodeUrl("mainnet") },
};

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SuiClientProvider networks={networks} defaultNetwork="devnet">
          <WalletProvider theme={darkTheme}>
            <Backdrop src={backdropImage} alt="backdrop" />
            <Header />

            <Routes>
              <Route element={<NotFound />} path={ROUTES.HOME.path} />
              <Route element={<Staking />} path={ROUTES.STAKE.path} />
              <Route element={<NotFound />} path={ROUTES.VALIDATORS.path} />
              <Route element={<NotFound />} path={ROUTES.NOT_FOUND.path} />
              <Route element={<NotFound />} path={"*"} />
            </Routes>

            <Footer />
          </WalletProvider>
        </SuiClientProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
