import { useCurrentAccount } from "@mysten/dapp-kit";
import Tabs from "../../base-components/Tabs";
import {
  Amount,
  AmountInput,
  ApyContainer,
  Asset,
  AssetLogo,
  AssetName,
  BottomDetails,
  Box,
  Button,
  Container,
  ContentContainer,
  ContentLeft,
  Details,
  DetailsLine,
  DetailsLineText,
  HoldersContainer,
  SeparatorCircleLeft,
  SeparatorCircleRight,
  SeparatorContainer,
  SeparatorInner,
  StakingRewardsImg,
  StatLabel,
  StatValueApy,
  StatValueHolders,
  StatValueTvl,
  StatsContainer,
  TvlContainer,
} from "./Staking.styles";
import { useCallback, useState } from "react";

import suiLogo from "../../assets/core/sui.png";
import rSuiLogo from "../../assets/core/rSUI.png";

import stakingRewards from "../../assets/staking-rewards.png";

export const Separator = () => (
  <SeparatorContainer>
    <SeparatorInner />
    <SeparatorCircleLeft />
    <SeparatorCircleRight />
  </SeparatorContainer>
);

export default function Staking() {
  const account = useCurrentAccount();

  const [depositAmount, setDepositAmount] = useState("");
  const onDepositInputChange = useCallback((e: any) => {
    setDepositAmount(e?.target?.value);
  }, []);

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const onWithdrawInputChange = useCallback((e: any) => {
    setWithdrawAmount(e?.target?.value);
  }, []);

  return (
    <Container>
      {account && <div>Connected to {account.address} </div>}

      <ContentContainer>
        <ContentLeft>
          <StakingRewardsImg src={stakingRewards} />

          <StatsContainer>
            <TvlContainer>
              <StatValueTvl>$123M</StatValueTvl>
              <StatLabel>TVL</StatLabel>
            </TvlContainer>

            <ApyContainer>
              <StatValueApy>8%</StatValueApy>
              <StatLabel>APY</StatLabel>
            </ApyContainer>

            <HoldersContainer>
              <StatValueHolders>1,234</StatValueHolders>
              <StatLabel>HOLDERS</StatLabel>
            </HoldersContainer>

          </StatsContainer>
        </ContentLeft>

        <Tabs>
          <Tabs.Tab label="Stake">
            <Box>
              <Amount>
                <Asset>
                  <AssetLogo src={suiLogo} />
                  <AssetName>SUI</AssetName>
                </Asset>

                <AmountInput
                  type="number"
                  onChange={onDepositInputChange}
                  value={depositAmount}
                  placeholder="0"
                />
              </Amount>

              <Details>
                <DetailsLine>
                  <DetailsLineText>You will receive</DetailsLineText>
                  <DetailsLineText>0 rSUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Network fee</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>
              </Details>

              <Button>Convert to rSUI</Button>

              <Separator />

              <BottomDetails>
                <DetailsLine>
                  <DetailsLineText>Epoch</DetailsLineText>
                  <DetailsLineText>Ends in: 01:10:23</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Validators</DetailsLineText>
                  <DetailsLineText>86</DetailsLineText>
                </DetailsLine>
              </BottomDetails>
            </Box>
          </Tabs.Tab>
          <Tabs.Tab label="Unstake">
            <Box>
              <Amount>
                <Asset>
                  <AssetLogo src={rSuiLogo} />
                  <AssetName>rSUI</AssetName>
                </Asset>

                <AmountInput
                  type="number"
                  onChange={onWithdrawInputChange}
                  value={withdrawAmount}
                  placeholder="0"
                />
              </Amount>

              <Details>
                <DetailsLine>
                  <DetailsLineText>You will receive</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Network fee</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>
              </Details>

              <Button>Unstake SUI</Button>

              <Separator />

              <BottomDetails>
                <DetailsLine>
                  <DetailsLineText>Epoch</DetailsLineText>
                  <DetailsLineText>Ends in: 01:10:23</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Validators</DetailsLineText>
                  <DetailsLineText>86</DetailsLineText>
                </DetailsLine>
              </BottomDetails>
            </Box>
          </Tabs.Tab>
        </Tabs>
      </ContentContainer>
    </Container>
  );
}
