import styled from "@emotion/styled";

export const Container = styled.div`
  height: calc(100vh - 162px);
  width: 100vw;

  padding: 32px 64px;
  padding-top: 72px;
  position: relative;

  font-size: 24px;
  text-align: center;
`;

export const Heading = styled.h1`
  font-size: 48px;
`;
